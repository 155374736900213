.hero {
  min-height: 100vh;
  min-width: 100vw;
  background: #f6f9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  .bg {
    background-image: url(https://htmlstream.com/preview/front-v4.3.1/assets/svg/components/card-11.svg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: 0.4;
  }

  .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 800px;
    z-index: 2;
    p {
      font-size: 2.5rem;
      color: #444;
    }
  }
  .animation {
    z-index: 3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
