.features {
  min-height: calc(100vh - 60px);
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  padding-bottom: 50px;
  h1 {
    margin: 50px 0;
  }
}
